import {h} from "preact";

const Text = ({children}) => children;
import {useEffect, useLayoutEffect, useState} from "preact/hooks";
import {Link} from "preact-router/match";
import LinksToSocialNetworks from "../LinksToSocialNetworks/LinksToSocialNetworks";
import {doc, root} from "../../helpers/browser_globals";
import styles from "./styles.scss";

let oldHref = doc.location?.href;
const config = {
    childList: true,
    subtree: true,
};
const bodyList = doc?.querySelector?.("body");

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [fixed, setFixed] = useState(0);
    const [transition, setTransition] = useState(false);
    useLayoutEffect(() => {
        root.addEventListener("scroll", checkScroll);
        if (root.scrollY > 500) {
            setFixed(2);
        }
    });

    useEffect(() => {
        let str = `@keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }`;
        let count = 30;
        let N = 100;
        let i = count;
        str += `ul > li, [main-attr] > * {
            opacity: 0;
            animation: fadeIn .4s ease forwards;
            animation-duration: ${(i - 1) * N}ms;
            }`
        for (let i = 1; i < count; i++) {
            str += `ul > li:nth-child(${i}), [main-attr] > *:nth-child(${i}) {
            opacity: 0;
            animation: fadeIn .4s ease forwards;
            animation-duration: ${(i - 1) * N}ms;
            }`
        }

        console.log("qqqqq strrrrrrrrrrrr", str);
        $('#for-script').html(`<style>
${str}
</style>`)
    }, [])

    const checkScroll = () => {
        const curScroll = root.scrollY;

        if (fixed === 1 && curScroll >= 500) {
            return setFixed(2);
        }

        if (!transition && curScroll >= 200) {
            setTransition(true);
        }

        if (transition && curScroll <= 200) {
            setTransition(false);
        }

        if (!fixed && curScroll >= 112) {
            return setFixed(1);
        }

        if (fixed && curScroll < 112) {
            return setFixed(0);
        }
    };

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (oldHref !== doc.location.href) {
                oldHref = doc.location.href;
                setIsMenuOpen(false);
            }
            if (oldHref !== doc.location.href) {
                oldHref = doc.location.href;
                setIsMenuOpen(false);
            }
        });

        observer.observe(bodyList, config);

        return () => {
            observer.disconnect();
        };
    }, []);

    const openMenu = () => {
        setIsMenuOpen(true);
        doc.documentElement.style.overflow = "hidden";
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
        doc.documentElement.style.overflow = "auto";
    };
    const closeMenu2 = () => {
        $('#specialButton').click()
        closeMenu()
    };

    const startWork = () => {
        try {


            if (root.location.pathname === "/404/") {
                root.location.href = "/contacts";
            }

            const requestForm = doc.querySelector("#request-form");
            const header = doc.querySelector("#header");
            const {top} = requestForm.getBoundingClientRect();
            const location = root.location.pathname;
            const urls = [
                "/optimization",
                "/sales",
                "/competitiveness",
                "/reputation-decisions",
                "/loyalty",
            ];
            let isServicesPage = 10;

            if (urls.includes(location)) {
                isServicesPage += 70;
            }

            const positionForm = Math.floor(
                root.scrollY + (top - header.clientHeight - isServicesPage)
            );
            root.scrollTo(0, positionForm);
            closeMenu();
        } catch (e) {

        }
    };

    const activeMenu = (isActive) => {
        const result = [styles.menu];
        if (typeof root !== "undefined") {
            if (isActive) {
                if (doc.body?.style) {
                    result.push(styles.menu_active);
                    doc.body.style.overflow = "hidden";
                } else {
                    doc.body.style.overflow = "";
                }
            }
        }
        return result.join(" ");
    };

    const handleClick = (e, url) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('LOOOG', e.target.href);
        location.href = url;
    }

    return (
        <header
            id="header"
            className={`${fixed >= 1 ? styles.fixed : ""} ${
                fixed > 1 ? styles.fixed_2 : ""
            } ${transition ? styles.transition : ""}`}
        >

            <div className={styles.headerContainer}>

                <Link className={styles.logo} href="/">
                    <img src="/assets/logo.svg" alt="logo" title="logo"/> <span
                    style={{
                        display: 'inline-block',
                        fontSize: '45px',
                        fontWeight: 'bolder',
                        marginTop: '14px',
                        marginLeft: '20px'
                    }}
                >ИТК</span>
                </Link>
                <div className={styles.navList}>

                    {/* <Link
                className={`${styles.navItem} ${styles.hoverText}`}
                href="/services"
            >
              <Text id="header.services">Услуги</Text>
            </Link> */}
                    {/* <Link
                className={`${styles.navItem} ${styles.hoverText}`}
                href="/team"
            >
              <Text id="header.about_company">Документация</Text>
            </Link> */}
                    {/* <Link
                className={`${styles.navItem} ${styles.hoverText}`}
                href="/"
            >
              <Text id="header.about_company">Главная</Text>
            </Link> */}
                    <Link
                        className={`${styles.navItem} ${styles.hoverText}`}
                        href="/info"
                    >
                        <Text id="header.about_company">Сведения об образовательной организации</Text>
                    </Link>
                    {/*<Link*/}
                    {/*    className={`${styles.navItem} ${styles.hoverText}`}*/}
                    {/*    href="/contacts"*/}
                    {/*>*/}
                    {/*    <Text id="header.contacts">Контакты</Text>*/}
                    {/*</Link>*/}

                    <a id="specialButton"  className={`${styles.navItem} ${styles.hoverText}`} href="#">Версия для слабовидящих</a>

                    {/*<div className={styles.menuVector1}/>*/}
                    {/*<button className={styles.navBtn} onClick={startWork}>*/}
                    {/*    <Text id="header.contact">Записаться на курс</Text>*/}
                    {/*</button>*/}
                    <div className={styles.menuVector2}/>
                    <img
                        className={styles.navBurger}
                        height={64}
                        width={31}
                        src="/assets/menu-hamburger.svg"
                        alt="burger"
                        title="burger"
                        onClick={openMenu}
                    />
                </div>

                <div id="burgerMenu" className={activeMenu(isMenuOpen)}>
                    <div className={styles.menu_content}>
                        <div className={styles.menuHeader}>
                            {/* <Link
                    className={styles.logo}
                    href="/"
                >
                  <img src="/assets/logo.svg" alt="logo" title="logo" />
                </Link>*/}
                            <img
                                className={styles.closeIcon}
                                src="/assets/components/header/close.svg"
                                alt="close icon"
                                title="close icon"
                                onClick={closeMenu}
                            />
                        </div>
                        <div className={styles.menuBody}>
                            <div className={styles.menuBlock}>
                                <div className={styles.menuPannel}>
                                    <Link
                                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                                        onClick={closeMenu}
                                        href="/info"
                                    >
                                        <Text id="burger_menu.contacts">Сведения об образовательной организации</Text>
                                    </Link>
                                    {/* <Link
                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                        onClick={closeMenu}
                        href="/projects"
                    >
                      <Text id="burger_menu.case">Кейсы</Text>
                    </Link>
                    <Link
                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                        onClick={closeMenu}
                        href="/services"
                    >
                      <Text id="burger_menu.services">Услуги</Text>
                    </Link> */}
                                    {/*<Link*/}
                                    {/*    className={`${styles.burgerTextLink} ${styles.hoverText}`}*/}
                                    {/*    onClick={closeMenu}*/}
                                    {/*    href="/contacts"*/}
                                    {/*>*/}
                                    {/*    <Text id="burger_menu.contacts">Контакты</Text>*/}
                                    {/*</Link>*/}
                                    <Link
                                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                                        onClick={closeMenu2}

                                    >
                                        <Text id="burger_menu.contacts">Версия для слабовидящих</Text>
                                    </Link>
                                </div>
                                {/* <div className={styles.menuPannel}>
                    <Link
                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                        onClick={closeMenu}
                        href="/team"
                    >
                      <Text id="burger_menu.team">Команда</Text>
                    </Link>
                    <Link
                        className={`${styles.burgerTextLink} ${styles.hoverText}`}
                        onClick={closeMenu}
                        href="/blog"
                    >
                      <Text id="burger_menu.blog">Блог</Text>
                    </Link>
                    <div className={styles.socialContainer}>
                      <LinksToSocialNetworks />
                      <div className={styles.contactBlock}>
                        <div className={`${styles.footerPhone  } h1` }>
                          <a href="tel:+7-932-234-51-23">+ 7 (932) 234-51-23</a>
                        </div>
                        <div className={`${styles.footerEmail  } h1`}>
                          <a href="mailto:it.consult.tag@gmail.com">it.consult.tag@gmail.com</a>
                        </div>
                      </div>
                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className={styles.burgerBackground}/>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
