import { h } from 'preact';
const Text = ({children}) => children;
import LinksToSocialNetworks from "../LinksToSocialNetworks/LinksToSocialNetworks";
import styles from "./styles.scss";

const Footer = () => {
 return (
   <footer>
     <div className={styles.footerContent}>
         <div className={styles.footerLeft}>
             <div className={styles.logo}>
                 <img src="/assets/logo.svg" alt="logo" title="logo"/> <span
                 style={{fontWeight: '800', fontSize: '35px', marginLeft: '10px'}}>ИТК</span>
             </div>
             <p className={styles.footerInfo}><Text id='footer.protected'>ООО "ИТК" 2023 г.</Text></p>
             <p className={styles.footerInfo} style={{paddingTop: '10px'}}><Text id='footer.protected'>ИНН
                 6154164899</Text></p>
             <p className={styles.footerInfo}><Text id='footer.protected'>ОГРН 1236100010970</Text></p>

             <p  className={styles.footerInfo} >
                         <a href="https://minobrnauki.gov.ru/"  target={"_blank"}
                            style={{textDecoration: 'underline'}}>Министерство науки и высшего образования</a>
             </p>
             <p className={styles.footerInfo}>
                 <a href="https://edu.gov.ru/contact/" className="bg-color-theme text-center text-regular"
                    style={{textDecoration: 'underline'}}>Министерство просвещения</a>
             </p>
             {/*<a className={styles.footerInfo}*/}
             {/*   onClick={() => location.href = "/svedenia-ob-obrazovatelnoy-organizazii/obrazovanie"}*/}
             {/*   style={{textDecoration: 'underline'}}>Сведения об образовательной*/}
             {/*    организации</a>*/}
             {/* <a onClick={() => location.href = "/privacy"} ><Text id='footer.privacy_policy'>Политика конфиденциальности</Text></a> */}
         </div>

         <div className={styles.footerRight}>
             <div className={styles.footerPhone}><a href="tel:+79959301020">+7 (995) 930-10-20</a></div>
             <div className={styles.footerEmail}><a href="mailto:it.consult.tag@gmail.com">it.consult.tag@gmail.com</a>
             </div>
             <LinksToSocialNetworks/>
         </div>
     </div>
       <div className={styles.backgroundCircle}/>
   </footer>
 );
};

export default Footer;
