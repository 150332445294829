import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>

          <h1>Платные образовательные услуги</h1>
          <h2>Документы</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/platnie/1_polozenie-platnye-uslugi.pdf'}>Положение о
                      порядке оказания платных образовательных услуг
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/platnie/2_prikaz-o-stoimosti.pdf'}>Приказ об утверждении
                      стоимости обучения
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/4_prikaz-o-stoimosti-24.pdf'}>Приказ об утверждении
                      стоимости обучения на 2024 год
                  </a>
              </li> <li>
                  <a target="_blank" href={'/assets/documents/dogovor.pdf'}>Договор на обучение ООО ИТК
                  </a>
              </li>
          </ul>
      </main>
  );
};

export default Success;
