import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>
          <h1>ОРГАНИЗАЦИЯ ПИТАНИЯ В ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ</h1>
          <h2>Условия питания для обучающихся ООО «ИТК»:</h2>
          <p>собственной столовой в ООО «ИТК» нет. Обучающиеся могут воспользоваться услугами точек общепита,
              которые находятся в шаговой доступности. Об этой возможности каждый обучающийся извещается в устной форме
              при заключении договора на обучение.</p>
          <h2>Условия охраны здоровья обучающихся:</h2>
          <p>в ООО «ИТК» имеется в наличии аптечка для оказания первой помощи с применением медицинских изделий в
              организациях, осуществляющих образовательную деятельность, в соответствии с требованиями приказа Минздрава
              России от 24 мая 2024 г. N 261н. В помещении ООО «ИТК» имеется кулер для обеспечения обучающихся
              питьевой водой. Все сотрудники организации прошли обучение по навыкам и приемам оказания первой помощи
              пострадавшим.</p>


      </main>
);
};

export default Success;
