


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Руководство и педагогический состав ООО «ИТК»</h1>
            <ul>
                <li>Генеральный директор: Тетеревлев Игорь Николаевич</li>
                <li>Телефон: +7 (995) 930-10-20</li>
                <li>Электронная почта: it.consult.tag@gmail.com</li>
                <li>Образование: ФГАОУ ВПО “Южный федеральный университет”, квалификация Инженер по специальности
                    “Системы автоматизированного проектирования” (2010г)
                </li>
            </ul>
            <p>
                Заместители руководителя организации отсутствуют
            </p>
            <ul>
                <li>Преподаватель: Веселая Анастасия Александровна</li>
                <li>Образование: ГОУ ВПО “Таганрогский государственный педагогический институт”, квалификация Учитель
                    математики и физики по специальности “Математика” с дополнительной специальностью “Физика” (2005г)
                </li>
                <li>Дополнительное профессиональное образование: Центр повышения квалификации Таганрогского института
                    имени А.П.Чехова (филиала) ФГБОУ ВО “РГЭУ (РИНХ)” по программе профессиональной переподготовки
                    “Педагогическое образование: информатика” (2023г)
                </li>
                <li>Ученая степень: Кандидат технических наук</li>

            </ul>
            <ul>
                <li>Общий стаж работы: 19 лет</li>
                <li>Стаж работы по специальности: 17 лет</li>
            </ul>

        </main>
    );
};

export default Success;
