
    import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
    import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Общество с ограниченной ответственностью “ИТК”</h1>
            <p><strong>Сокращенное наименование:</strong> ООО “ИТК”</p>
            <p><strong>Дата создания:</strong> 20.04.2023</p>

            <h2>Учредители:</h2>
            <ul>
                <li>Тетеревлев Игорь Николаевич ИНН 615425657556</li>
            </ul>

            <p><strong>Юридический адрес:</strong> 347900, Ростовская область, г.о. город Таганрог, г Таганрог, пер.
                Тургеневский, 11, ком. 1-7.</p>
            <p><strong>Фактический адрес:</strong> 347900, Ростовская область, г.о. город Таганрог, г Таганрог, пер.
                Тургеневский, 11, ком. 1-7.</p>
            <p>Образовательная организация представительств и филиалов в том числе, находящихся за пределами Российской
                Федерации не имеет.</p>

            <h2>График работы:</h2>
            <ul>
                <li>Пн 9.00-18.00</li>
                <li>Вт 9.00-18.00</li>
                <li>Ср 9.00-18.00</li>
                <li>Чт 9.00-18.00</li>
                <li>Пт 9.00-18.00</li>
                <li>Сб выходной</li>
                <li>Вс выходной</li>
            </ul>

            <p><strong>Номер телефона:</strong> +7 (995) 930-10-20</p>
            <p><strong>Адрес электронной почты:</strong> <a
                href="mailto:it.consult.tag@gmail.com">it.consult.tag@gmail.com</a></p>
            <p><strong>Адрес официального сайта:</strong> <a
                href="https://itconsult-web.ru">https://itconsult-web.ru</a></p>

            <p><strong>Место оказания образовательной деятельности:</strong> 347900, Ростовская область, г.о. город
                Таганрог, г Таганрог, пер. Тургеневский, 11, ком. 1-7.</p>


        </main>
    );
};

    export default Success;
